.dropdown-container {
  position: relative;
  width: 100%;
}

.dropdown-button-open {
  width: 100%;
  padding: 8px 16px;
  border-top: 1px solid #c6cbd9;
  border-right: 1px solid #c6cbd9;
  border-left: 1px solid #c6cbd9;
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
  background: white;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.dropdown-button-close {
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #c6cbd9;
  border-radius: 8px;
  background: white;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.dropdown-button:hover {
  border-color: #1f93ef;
}

.dropdown-placeholder {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.dropdown-icon {
  display: flex;
  align-items: start;
  margin-top: 2px;
}

.dropdown-placeholder-option {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.dropdown-options {
  width: 100%;
  border-bottom: 1px solid #c6cbd9;
  border-right: 1px solid #c6cbd9;
  border-left: 1px solid #c6cbd9;
  border-radius: 0px 0px 8px 8px;
  background: white;
  overflow: hidden;
  padding: 0px 16px 16px 16px;
  transition: background 0.2s ease;
}

.dropdown-options-wrapper {
  max-height: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.dropdown-options-wrapper.open {
  max-height: 500px;
  opacity: 1;
}

.dropdown-options-wrapper.closed {
  max-height: 0;
  opacity: 0;
}

.dropdown-option {
  width: 100%;
  padding: 14px;
  border: none;
  border-bottom: 1px solid var(--Neutral-30, #e2e2ea);
  background: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 7px;

  color: #16161e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.dropdown-option:hover {
  background: #f0f7ff;
}

.dropdown-option.selected {
  background: var(--Blue-10, #ebf6ff);
}

.dropdown-option-index {
  display: flex;
  background-color: #ecf5f8;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
}

.dropdown-option-index.selected {
  background-color: #ffffffd9;
}

.contributors-count {
  color: #666;
  font-size: 14px;
}

.member-list {
  padding: 0 16px;
}

.member-item {
  padding: 16px;
  border: 1px solid #c6cbd9;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.member-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f0f0f0;
}

.member-info {
  flex: 1;
}

.member-name {
  font-weight: 500;
  color: #16161e;
}

.member-email {
  color: #666;
  font-size: 14px;
}

.drawer-footer {
  padding: 16px;
  border-top: 1px solid #c6cbd9;
}

.assign-button {
  width: 100%;
  padding: 12px;
  background: #1f93ef;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.assign-button:hover {
  background: #1a7fd3;
}
