.custom-drawer {
  position: fixed;
  top: 0;
  right: -500px;

  width: max-content;
  height: 100%;
  background-color: white;
  transition: right 0.5s ease;
  box-shadow: none;
  z-index: 1000;
  margin-top: 80px;
}

.custom-drawer.open {
  right: 0;
}

.drawer-content {
  padding: 20px;
  height: 100%;
  // overflow-y: auto;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
