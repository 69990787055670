.sidebar {
  // background-color: #1c1c1c;
  background-color: #262631;
  color: #656575;
  padding: 24px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 256px;
  transition: width 0.6s cubic-bezier(0.43, 1.28, 0.4, 1.02);
}

.sidebar.collapsedState {
  width: 90px;
}

.sidebarMobile {
  background-color: #1c1c1c;
  color: #656575;
  width: 256px;
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  transition-duration: 600ms;
  left: 0;
  z-index: 12;
}

.scrollable-items-sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  // height: 65vh;
  padding-right: 14px;
  margin-right: -20px;

  &::-webkit-scrollbar {
    // height: 8px;
    scrollbar-gutter: stable;
    width: 7px;
    padding-right: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff76;
    scrollbar-gutter: stable;
    padding-right: 14px;
  }

  .sub-item-border {
    border-left: 0px solid #656575;
  }
}

.closeMobile {
  left: -256px;
  transition-duration: 600ms;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0px !important;
}

.sidebar li {
  margin-bottom: 10px;
  cursor: pointer;
}

.sidebar li:hover > a {
  border-radius: 4px;
  // background-color: #656575;
  background-color: #535362;
  color: white;
}

.sidebar li:hover > a > img {
  filter: brightness(0) invert(1);
}

.sidebar .active:hover > a {
  border-radius: 4px;
  background-color: #1f93ef;
  color: white;
}

.sidebar li:hover > div {
  border-radius: 4px;
  // background-color: #656575;
  background-color: #535362;
  color: white;
}

.sidebar li:hover > div > img {
  filter: brightness(0) invert(1);
}

.sidebar .active:hover > div {
  border-radius: 4px;
  background-color: #1f93ef;
  color: white;
}

.sidebarMobile ul {
  list-style-type: none;
  padding: 0;
  margin-top: 30px;
}

.sidebarMobile li {
  margin-bottom: 10px;
  cursor: pointer;
}

.sidebarMobile li:hover > a {
  border-radius: 4px;
  // background-color: #656575;
  background-color: #535362;
  color: white;
}

.logout {
  display: flex !important;
  align-items: center !important;
  padding: 10px;
  height: 50px !important;
}

.logout:hover {
  border-radius: 4px;
  // background-color: #656575;
  background-color: #535362;
  color: white;
  padding: 10px;
  cursor: pointer;
  height: 50px !important;
}

.menu {
  a {
    border-radius: 4px;
    color: white;
    text-decoration: none;
  }
  &.active {
    a {
      // background-color: #656575;
      background-color: #1f93ef;
      text-decoration: none;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.disabled {
    a {
      background-color: #07070c !important;
      color: #7e7e8f !important;
      cursor: not-allowed;
      &:hover {
        background-color: #07070c !important;
        color: #7e7e8f !important;
      }
    }
  }

  div {
    border-radius: 4px;
    color: white;
    text-decoration: none;
  }
  &.active {
    div {
      // background-color: #656575;
      background-color: #1f93ef;
      text-decoration: none;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.disabled {
    div {
      background-color: #07070c !important;
      color: #7e7e8f !important;
      cursor: not-allowed;
      &:hover {
        background-color: #07070c !important;
        color: #7e7e8f !important;
      }
    }
  }
}

.titleText {
  color: #f1f1f1;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  transition-duration: 600ms;
  height: 28px;
  width: 116px;
  // margin: 30px 20px 0px 20px;
}

.hiddenTransition {
  // margin: 30px 0px 0px 0px;
  transition-duration: 600ms;
}

.dot {
  color: #72d424;
}

.beta {
  font-size: 8px;
  background: #22b60e;
  border-radius: 5px;
  color: white;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 4px;
}

.menuItemSub {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  padding: 10px;
  text-decoration: none;

  .vecImage {
    height: 24px;
    position: relative;
    width: 24px;
  }

  // .vecImage2 {
  //   height: 24px;
  //   position: relative;
  //   width: 24px;
  // }
  .textWrapper {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    width: fit-content;
    text-decoration: none;
    text-wrap: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
  }
}
.menuItemSub2 {
  display: inline-flex;
  gap: 8px;
  width: 100%;
  height: 100%;
  // padding-top: 9px;
  // padding-right: 16px;
  // padding-bottom: 8px;
  // padding-left: 9px;
  padding: 10px;
  text-decoration: none;

  .vecImage {
    height: 24px;
    position: relative;
    width: 24px;
  }
  .textWrapper {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    width: fit-content;
    text-decoration: none;
    text-wrap: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// .menuItem {
//   display: inline-flex;
//   gap: 8px;
//   width: 100%;
//   height: 100%;
//   padding-top: 8px;
//   padding-right: 16px;
//   padding-bottom: 8px;
//   padding-left: 24px;
//   text-decoration: none;

//   .vecImage {
//     height: 17px;
//     position: relative;
//     width: 17px;
//   }
//   .textWrapper {
//     font-size: 14px;
//     font-weight: 400;
//     letter-spacing: 0;
//     line-height: normal;
//     width: fit-content;
//     text-decoration: none;
//     text-wrap: nowrap;
//     width: 100%;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }

.hidden {
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}

p {
  margin-bottom: 0 !important;
}

.toggle-button {
  position: absolute;
  cursor: pointer;
  right: -15px;
  top: 30px;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  z-index: 13;
}

.toggle-button-main {
  cursor: pointer;
  border-radius: 100%;
}

.rotate {
  transform: rotate(180deg);
}

.hiddenTransition {
  font-size: 0px;
  transition-duration: 600ms;
}

.ft-size-12 {
  font-size: 12px;
}

// .company-logo-cont{
//   // padding: 4px;
//   //  width: 45px;
// }

.sub-sidebar {
  position: absolute;
  background: #262631;
  color: #fff;
  overflow: hidden;
  z-index: 12;
  height: 100%;
  top: 0;
  left: 100%;
  width: 0px;
  border-left: 0px;
  transition: width 0.6s cubic-bezier(0.43, 1.28, 0.4, 1.02), border-left 0.6s cubic-bezier(0.43, 1.28, 0.4, 1.02);
}

.sub-sidebar li:hover > a > img {
  filter: brightness(0) invert(1);
}

.sub-sidebar.open {
  width: 240px;
  border-left: 1px solid #535362;
  padding: 24px;
}

.p-heading-style {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px !important;
}

.toggle-svg {
  border-radius: 24px;
  border: 1px solid var(--Gray-30, #535362);
  background: var(--Gray-90, #16161e);
  width: 4px;
  height: 4px;
}

.right-arrow {
  transform: rotateZ(270deg);
  transition: 0.6s;
}

.left-arrow {
  transform: rotateZ(90deg);
  transition: 0.6s;
}

.mt-8i {
  margin-top: 8px !important;
}
.mb-24p {
  margin-bottom: 24px !important;
}

.beta-tag {
  display: flex;
  padding: 0.125rem 0.625rem;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.9);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  border-radius: 0.75rem;
  background: var(--Blue-20, #a8d6fb);
}
