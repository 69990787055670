.card-type-title {
  color: rgba(22, 22, 30, 0.9);
  font-weight: 500;
}

.blue-text {
  font-size: 12px;
  font-weight: 500;
  color: #1f93ef;
  cursor: pointer;
  text-wrap: nowrap;
}
