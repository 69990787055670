.questionTags {
  color: var(--Text-on-Dark-Surface-Medium-Emphasis, rgba(22, 22, 30, 0.6));
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  border-radius: 4px;
  border: 0.6px solid var(--Tags-SDG-Stroke, #d6ecfc);
  background: var(--Tags-SDG-Fill, #e3f2fd);
  padding: 0.19rem 0.38rem;
}
