// Base chip styles
.chip {
    border-radius: 12px;
    border: 1px solid var(--Neutral-30, #E2E2EA);
    background: var(--Text-on-Dark-Surface-High-Emphasis, #FFF);
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    color: var(--Text-on-White-Surface-High-Emphasis, #16161E);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer; // Make it clickable
    user-select: none; // Prevent text selection on click
  
    &.active {
      background: var(--Blue-30, #64B5F6);
      border-color: var(--Blue-30, #64B5F6);
      color: var(--Text-on-Dark-Surface-High-Emphasis, #FFF);
      font-weight: 500;
    }
  }
  