#custom-placeholder-input::placeholder {
  font-size: 12px; /* Adjust the font size as needed */
}
#custom-placeholder-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px;
}
#custom-placeholder-input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  opacity: 1; /* Firefox sets opacity to 0.5 by default */
}
#custom-placeholder-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 12px;
}
#custom-placeholder-input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 12px;
}

.pdfHeading{
  color:#1671CA;
  font-size: 20px;
  font-weight: 600;
}
.pdfSubHeading{
  color:#1671CA;
  font-size: 16px;
  font-weight: 600;
}
.tableContainer{
  border: 0.5px solid #0D47A1;
  border-radius: 8px;
}
.tableRow{
  width: 100%;
  border-bottom: 0.5px solid;
  border-color: #0D47A1;
}
.tableCell{
  border-right: 0.5px solid;
  border-color: #0D47A1;
}
.cellText12{
  font-weight: 500;
  font-size: 12px;
  color: #16161E;
}
.cellText10{
  font-weight: 500;
  font-size: 10px;
  color: #16161E;
}
.width20{
  width: 20%;
}
.pageNo{
  font-size: 12px;
  font-weight: 500;
  background-color: #1671CA;
  color: white;
  padding: 8px 14px;
}
.sticky-invoiceTable-cell {
  position: sticky;
  right: 0;
  background-color: #f2f3f7;
}



