.ps-search-bar-wide{
  min-width: 340px !important
}

.action-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 32px;
  margin-bottom: 32px;
}
