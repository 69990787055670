.report-detail-container {
  border-top: 1px solid #c5c5c5;
  padding-top: 35px;

  .ps-search-bar {
    width: 100%;
  }

  .your-reports {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
  }

  .filters-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;

    .ant-select-selector {
      min-width: 130px;
      width: 100%;
    }
  }
}

.labelTextModal {
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 0.5rem;
}

.dividerContainer {
  margin-top: 30px;
  width: 100%;

  .line-divider {
    color: #1f93ef;
    text-align: left;
    position: relative;
    margin-bottom: 16px;
    font-size: 12px !important;
  }

  .line-divider .span-line-divider {
    background: #fff;
    padding: 0 10px 0px 0px;
    position: relative;
    z-index: 5;
  }

  .line-divider:before {
    content: "";
    display: block;
    border-top: solid 2px #1f93ef;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 10px;
    z-index: 1;
  }
}

.MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-top: 0px !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag {
  margin-top: -0.38rem !important;
}
