.logout-main-cont {
  border-radius: 4px;
  background: var(--Grey-50, #2e2e3a);
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.16), 0px 16px 24px -8px rgba(0, 0, 0, 0.16);
}
.logout-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 12px 16px 12px 16px;
  gap: 4px;

  .user-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--White, #fff);
  }
  .user-email {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: var(--White, #fff);
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.custom-img-fluid {
  max-width: 100%;
  max-height: 32px;
}

.header-heading {
  font-size: 16px;
  font-weight: 500;
}

.sub-header-heading {
  color: rgba(22, 22, 30, 0.7);
  font-size: 12px;
  font-weight: 400;
}
