
.searchFilter{
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 8px;
  align-items: center;
  margin-top: -50px;
  z-index: 2;
}

  .screen-heading{
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;

   
  }
.dataquality-wrapper{
    display: flex;
    flex-direction: column;
    height: calc(100% - 35px);

    .bodycontianerStyel{
        // background: white;
        height: 100%;
        margin: 20px 0;
    }
 
    .Mui-selected{
      background-color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .MuiTabs-indicator{
      display: none;
    }
   
    
}



// .status-circle {
//     border-radius: 50%;
//     padding: 5px 10px;
//     border: 1px solid #ccc;
//     display: inline-block;
//     margin-right: 10px;
//   }
  
//   .status-complete {
//     background-color: #4CAF50; /* Green */
//     color: white;
//   }
  
//   .status-warning {
//     background-color: #ffeb3b; /* Yellow */
//     color: black;
//   }
  
//   .status-error {
//     background-color: #f44336; /* Red */
//     color: white;
//   }
  
//   .checked {
//     color: #4CAF50; /* Green */
//   }
  
//   .unchecked {
//     color: #f44336; /* Red */
//   }

//   .custom-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
//     display: flex;
//     justify-content: space-around;
//   }
  
//   .custom-tabs .ant-tabs-nav .ant-tabs-tab {
//     border: none; /* Remove borders from tabs */
//     border-radius: 6px;
//     background-color: transparent; /* Default background */
//   }
  
//   .custom-tabs .ant-tabs-nav .ant-tabs-tab-active {
//     background-color: #64B5F6; /* Background color for active tab */
//     color: #fff; /* Text color for active tab */
//   }
  
//   .custom-tabs .ant-tabs-nav .ant-tabs-tab:hover {
//     background-color: rgba(100, 181, 246, 0.2); /* Optional hover effect */
//   }