.loader-15 {
	background: currentcolor;
	position: relative;
	animation: loader-15 1s ease-in-out infinite;
	animation-delay: 0.4s;
	width: .25em;
	height: .5em;
	margin: 0 .5em;
	&:after,
	&:before {
		content: '';
		position: absolute;
		width: inherit;
		height: inherit;
		background: inherit;
		animation: inherit;
	}
	&:before {
		right: .5em;
		animation-delay: 0.2s;
	}
	&:after {
		left: .5em;
		animation-delay: 0.6s;
	}
}

@keyframes loader-15 {
	0%,
	100% {
		box-shadow: 0 0 0 currentcolor, 0 0 0 currentcolor;
	}
	50% {
		box-shadow: 0 -.25em 0 currentcolor, 0 .25em 0 currentcolor;
	}
}

.bLoader{
	min-height: 24px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.loader-18 {
		position: relative;
		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			border-radius: 50%;
			border: .1em solid transparent;
			border-bottom-color: currentcolor;
			top: -5px!important;
			left: -8px !important;
			animation: 1s loader-18 linear infinite;
		}
		&:before {
			width: 1em;
			height: 1em;
		}
		&:after {
			width: .8em;
			height: .8em;
			top: .1em;
			left: .1em;
			animation-direction: reverse;
		}
	}
	
	@keyframes loader-18 {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.bLoaderNoWidth{
	min-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	.loader-18 {
		position: relative;
		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			border-radius: 50%;
			border: .1em solid transparent;
			border-bottom-color: currentcolor;
			top: -5px!important;
			left: -8px !important;
			animation: 1s loader-18 linear infinite;
		}
		&:before {
			width: 1em;
			height: 1em;
		}
		&:after {
			width: .8em;
			height: .8em;
			top: .1em;
			left: .1em;
			animation-direction: reverse;
		}
	}
	
	@keyframes loader-18 {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}