.no-scroll {
  overflow: hidden !important;
  height: 100%;
}

.category-tags {
  padding: 8px 16px;
  border-radius: 16px;
  border: 1px solid #7e7e8f;
  color: #7e7e8f;
  font-size: 12px;
}

.create-sim-button {
  max-width: 185px;
  font-size: 16px;
}

.create-new-button-wide {
  max-width: 250px;
  font-size: 16px;
}

.create-new-button-widest {
  max-width: 300px;
  font-size: 16px;
}

.description-text {
  max-width: 150px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-title-plan {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px !important;
}

.modal-container-wide {
  width: 650px;
}

.thin-modal-container {
  width: 350px;
}

.fs-12p {
  font-size: 12px;
}

.empty-box {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  overflow-y: auto;
  padding: 24px;
  margin-top: 24px;
  flex: 1 1 auto;
  min-height: 300px;
}
.data-container-box-graph {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  // overflow-y: auto;
  padding: 24px;
  margin-top: 24px;
  min-height: 430px;
  flex: 1 1 auto;
}
.data-container-box {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  overflow-y: auto;
  padding: 24px;
  margin-top: 24px;
  min-height: 430px;
  
  .w-130p {
    width: 130px;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    height: 8px;
    scrollbar-gutter: stable;
    width: 7px;
  }
  &:hover::-webkit-scrollbar {
    height: 8px;
    scrollbar-gutter: stable;
    width: 7px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: #00000033;
    scrollbar-gutter: stable;
  }
}

.button-content-container {
  width: 400px;
  margin-top: 16px;
}

.create-new-button {
  max-width: 150px;
  font-size: 16px;
}

.box-title-name {
  font-size: 20px;
  font-weight: 600;
}

.custom-textfield {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;

  label {
    font-weight: bold;
    margin-bottom: 8px;
  }

  textarea {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;

    &:focus {
      border: 1px solid #1f93ef !important;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

.label-text-modal {
  font-size: 14px;
  font-weight: 500;
}

.h-300p {
  height: 300px;
}

.delete-modal-font {
  font-size: 18px;
  font-weight: 500;
}

.delete-modal-small-font {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
}
