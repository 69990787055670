.progress-container-completion {
  width: 100px;
  background-color: #e2e2ea;
  overflow: hidden;
  border-radius: 10px;
  height: 4px;

  .progress-bar {
    height: 4px;
    background-color: #2196f3;
  }
}

.completion-text {
  text-wrap: nowrap;
  width: 45px;
}