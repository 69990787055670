.evidence-button {

  height: 28px;
  width: 28px;
  border-radius: 28px;
  gap: 8px;
  background-color: #656575;
  border: 0px;

}

.evidence-button:hover {
  background-color: #535362;
  position: relative;
}

.upload-box {
  width: 100%;
  border: 1px solid #DAE2ED;
  height: 4rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px #F3F6F9;
}

.upload-file-button {
  margin-right: 0.5rem;
  background-color: #F1F1F1;
  border: 1px solid #959595;
  border-radius: 4px;
}

.delete-button {
  background-color: #FF4343;
  border: none;
  height: 20px;
  padding: 1px 10px 1px 10px;
  font-size: 12px;
  color: white;
  border-radius: 5px;
}

.preview-button {
  background-color: white;
  border: 1px solid #1671CA;
  border-radius: 5px;
  color: #1671CA;
  height: 30px;
  padding: 1px 14px 1px 14px;
}

.file-error-box{
  padding: 4px 12px 4px 12px;
  display: flex;
  background-color: #F44336;
  color: white;
  width: 260px;
  border-radius: 8px;
  font-size: 14px;
  gap: 5px
}

.assignQuestion{
  
}