.main-wrappper {
  background: white;
  border-radius: 8px;
}
.esg-filter-wrapper{
  .custom-drawer{
    margin-top: 110px;
  }
}
.esg-table {
  border-collapse: separate;
  // border: solid #7e7e8f 1px;
  border-radius: 6px;
  table-layout: fixed;
  width: 100%;
  padding: 24px;
  overflow: auto;

  .esg-table ,table{
    width: 100%;
  }

  td,
  th {
    border: solid #c6cbd9 1px;
    font-size: 14px;
    font-weight: 500;
  }

  th {
    background-color: #f2f3f7;
    padding: 12px 32px;
    text-align: start;
    text-wrap: nowrap;
    color: #2e2e3a;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  td {
    padding: 14px 32px;
    color: #2e2e3a;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  th:last-child{
    text-align: center;
  }

  
  .small-width-columns {
    width: 80px;
    padding: 0px !important;
    text-align: center;
  }

  .xs-column {
    width: 40px;
    padding: 0px !important;
    text-align: center;
  }

  .medium-width-columns {
    width: 200px;
  }

  .large-width-columns {
    max-width: 300px;
  }

  .year-tabledata{
    text-align: center;
  }
}

.empty-wrapper-esg{

  .empty-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
