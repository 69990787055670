.download-snackbar {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 9999;
  background: #2e2e3a;
  border-radius: 0.25rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  min-width: 20.93rem;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  animation: slideIn 0.3s ease-out;

  .download-content {
    display: flex;
    align-items: start;
    gap: 0.62rem;
  }

  .spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
  }

  .success-icon {
    display: flex;
    align-items: start;
  }

  .download-text {
    display: flex;
    flex-direction: column;
    gap: 0.15rem;

    .download-text-title {
      color: rgba(255, 255, 255, 0.9);
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.375rem;
    }

    .download-text-file-name {
      color: rgba(255, 255, 255, 0.9);
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 15rem;
    }
  }

  .download-close-button {
    display: flex;
    align-items: start;
    height: 44px;

    .close-button {
      display: flex;
      align-items: start;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
