.env-breakdown-wrapper {
  background: white;
  margin-top: 16px;
  border-radius: 8px;
}

.heading-cont {
  color: #2e2e3a;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-top: 24px;
}
.bread-crumbs-wrapper {
  padding: 24px;
}

.env-beakdown-table {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 24px 24px 24px;
  overflow: auto;

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;

    th,
    td {
      border: 1px solid #c6cbd9;

      &:first-child {
        border-radius: 4px;
      }
    }

    th {
      background-color: #f2f3f7;
      color: #2e2e3a;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    td {
      color: #2e2e3a;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      // padding:32px 12px 32px 12px;
      // width: 20px;
      text-wrap: nowrap;
    }
  }

  .scrollable {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 6px;
      width: 8px;
      scrollbar-gutter: stable;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      scrollbar-gutter: stable;
      border-radius: 8px;
    }
    .table td {
      padding: 28px 8px 28px 8px;
    }
  }

  .first-table .table td {
    padding: 30px 8px 30px 8px;
  }
}

.env-beakdown-table .thirdtable .table td {
  padding: 15px 0 16px 0;
}

.remark-textArea {
  min-width: 330px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #9a9aaf;

  &:focus{
    outline: unset;
  }
}
