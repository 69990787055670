.add-new-row {
    // width: fit-content;
    max-width: fit-content;
    margin-top: 10px;
}
.foreword-container{
    border: 1px solid #ccc;
    border-radius: 8px;
}

.esg-table-content .accordion-custom .show{
    padding:  unset;
}
.without-head{
    border-collapse: collapse;
    width: 80%;
    
}

.esg-table-content .form-table th{
    text-align: start;
}

.esg-table-content .form-table .no-style-input{
    // width: unset;
}
// .esg-table-content .form-table{
//     border: 1px solid #ccc;
// }

// .esg-table-content .form-table td{
//     border-left: solid #9A9AAF 1px;
//     border-top: solid #9A9AAF 1px;
// }
// .esg-table-conten .form-table td:first-child{
//     border-left: none;
// }