@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.main-header-heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px !important;
}

.form-select option.text-capitalize {
  text-transform: initial;
}

.header-container {
  padding: 16px 24px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  top: 0;
  background: transparent;
  width: 100%;
  background: inherit;
  position: sticky;
  z-index: 2;
  border-bottom: 1px solid rgb(203 203 203);
}

input#email {
  font-family: "Poppins", sans-serif !important;

  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-family: "Poppins", sans-serif !important;
  }
}

.kb-wrapper {
  font-family: "Poppins", sans-serif;
  .rightSideContainer {
    background-color: #f2f3f7;
    min-height: 100vh;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.input-error {
  border: 1px solid #ef1f1f !important;
}

.btn-info {
  background: #64b5f6 !important;
  color: white !important;
  border-color: #64b5f6 !important;

  &:hover {
    background: #1a8eeb !important;
  }
}

.btn-transparent {
  background: white !important;
  color: #16161ee0 !important;
  border-color: #c6cbd9 !important;

  &:hover {
    background: #c6cbd9 !important;
  }
}

.btn-outline-info {
  border-color: #1a8eeb !important;
  color: #1a8eeb !important;
}

.stickyTableCell {
  box-shadow: 10px 0 5px -2px #888;
  position: sticky;
  background: white;
  right: 0;
  text-align: -webkit-center !important;
}

.stickyTableCellHeader {
  box-shadow: 10px 0 5px -2px #888;
  position: sticky;
  right: 0;
  background: white;
  font-weight: 700 !important;
}

.stickyTableCellHeader2 {
  box-shadow: 10px 0 5px -2px #888;
  position: sticky;
  right: 71px;
  background: white;
  font-weight: 700 !important;
}

.stickyTableCell2 {
  box-shadow: 10px 0 5px -2px #888;
  position: sticky;
  background: white;
  right: 71px;
}

.measureTableHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px 0px 24px 0px;
}

.mainDashContainer {
  display: flex;
  overflow: hidden;
  height: 100vh;
}

.ps-search-bar.MuiTextField-root > label {
  top: -6px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.me-10 {
  margin-right: 10px;
}

.mw-175p {
  min-width: 175px;
}

.w-185p {
  width: 185px;
}

.ms-10 {
  margin-left: 10px;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-70 {
  width: 70%;
}

.h-85 {
  height: 85%;
}

.color-red {
  color: red;
}

.cursor-disabled {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.btn {
  padding: 8px 24px 8px 24px !important;
  width: 100%;
  text-wrap: nowrap !important;
  min-width: 135px;
}

.btn-small {
  box-shadow: none;
  border: none;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
}

.btn-success {
  background: #1f93ef !important;
  color: white !important;
  border-color: #1f93ef !important;

  &:hover {
    background: #096bbb !important;
  }
}

.btn-danger {
  background: #ff535b !important;
  color: white !important;
  border-color: #ff535b !important;

  &:hover {
    background: #c7595e !important;
  }
}

.btn-outline-dark {
  color: #16161e !important;
  border-color: #c6cbd9 !important;

  &:hover {
    background: white !important;
    color: #64b5f6 !important;
    border-color: #64b5f6 !important;
  }
}

.btn-approved-disabled {
  background-color: #05e018 !important;
  border-color: #5aff67 !important;
  color: #ffffff !important;
}

.btn-approved {
  background-color: #23c118 !important;
  border-color: #23c118 !important;
  &:hover {
    background-color: #11a007 !important;
  }
  color: #ffffff !important;
}

.btn-outline-success {
  border-color: #1f93ef !important;
  color: #1f93ef !important;
  &:hover {
    color: #0f72c4 !important;
    background-color: #e3f2fd !important;
    img {
      filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(544%) hue-rotate(273deg) brightness(113%)
        contrast(100%);
    }
  }
}

.btn-outline-danger {
  border-color: red !important;
  color: red !important;
  &:hover {
    color: red !important;
    background-color: rgb(255, 224, 224) !important;
    img {
      filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(544%) hue-rotate(273deg) brightness(113%)
        contrast(100%);
    }
  }
}

.text-wrapper-bold {
  font-weight: bold;
}

.ant-select-item-option {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.ant-select-item-option-content{
  text-align: left !important;
}

.ant-select-dropdown {
  z-index: 100000;
}

.ant-select-selection-placeholder {
  z-index: 1000;
  color: rgba(22, 22, 30, 0.7) !important;
  font-size: 0.75rem;
}

.ant-select-item-option-selected {
  background-color: #ebf6ff !important;
  color: black;
}

.ant-select-item-option-active.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  // border: 1px solid #22b60e;
  background-color: #ebf6ff;
  color: black;
}

.ant-select-selector {
  font-family: "Poppins" !important;
  border-radius: 0.25rem !important;
}

.clickable-text {
  color: #157cd0;
  text-decoration: underline;
  cursor: pointer;
}

.w-80p {
  width: 80px;
}
.w-170p {
  width: 170px;
}

.h-150p {
  height: 150px;
}

.h-500p {
  height: 500px;
}

.h-10rems {
  height: 10rem;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}
.btn-disabled-solid {
  background-color: #e2e2ea !important;
  color: #9a9aaf !important;
  border-color: #e2e2ea !important;
}

.page-header-text {
  font-size: 20px;
  font-weight: bold;
}

.w-100vh {
  width: 100vh;
}

.h-100vh {
  height: 100vh;
}

.light-border-bottom {
  border-bottom: 1px solid #c6cbd9;
}

.error-red-band {
  background-color: #f44336;
  color: white;
  padding: 5px 13px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.div-loader {
  position: relative;
  overflow: hidden;
  border-radius: 100%;
}

.div-loader:before {
  content: "";
  background-image: conic-gradient(#04b0ee 20deg, transparent 120deg);
  height: 150%;
  width: 150%;
  position: absolute;
  left: -25%;
  top: -25%;
  animation: rotate 2s infinite linear;
}
@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}
.div-loader:after {
  content: "";
  height: 90%;
  width: 90%;
  position: absolute;
  background-color: white;
  border-radius: 100%;
  top: 3%;
  left: 3%;
  color: #04b0ee;
  display: grid;
  place-items: center;
  font-size: 20px;
  letter-spacing: 6px;
  z-index: 0;
}

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.6);
}

.main-page-title {
  font-size: 20px;
  font-weight: 500 !important;
}

.heading-h6 {
  color: #16161e;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.subtitle-1 {
  color: #16161e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.subtitle-2 {
  color: #16161e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.subtitle-2-overdue {
  color: #FF535B;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.caption-1-Regular {
  color: rgba(22, 22, 30, 0.7);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.caption-1-Regular-overdue {
  color: #FF4D4F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.caption-2-Regular {
  color: rgba(22, 22, 30, 0.7);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.card-layout {
  display: flex;
  // padding: 12px 14px 10px 12px;
  border-radius: 8px;
  border: 0.8px solid var(--Neutral-30, #e2e2ea);
  background: var(--White-100, #fff);
}

.multi-toggle-wrapper {
  display: flex;
  padding: 2px;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  background: var(--Neutral-10, #f2f3f7);
  width: fit-content;
}
.bg-trans {
  background-color: transparent !important;
}
.body-2 {
  color: #16161e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
}

.body-1-Regular {
  color: rgba(22, 22, 30, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.body-2-Regular {
  color: rgba(22, 22, 30, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

p {
  margin: 0;
  padding: 0;
}

.caption-1-Emphasized {
  color: #16161e;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #64b5f6 !important;
  background-color: white !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: #64b5f6 !important;
}

.ant-steps-item-title {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.ant-steps-item-description {
  font-size: 12px !important;
  font-weight: 400 !important;
  max-width: 170px !important;
}

.ant-steps-item-active {
  .ant-steps-item-icon {
    background-color: #64b5f6 !important;
    border-color: #64b5f6 !important;
  }
}

.ant-steps-item-finish {
  .ant-steps-item-title::after {
    background-color: #64b5f6 !important;
  }
}

.ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: #38cb24 !important;
    border-color: #38cb24 !important;

    .ant-steps-icon {
      color: white !important;
    }
  }
}

.ant-switch-checked {
  background-color: #38cb24 !important;
}

// .ant-switch-handle {
//   background-color: white;
// }

.custom-input-2 {
  border-radius: 0.25rem !important;
  font-size: 0.75rem;
}

.custom-input-2::placeholder {
  color: rgba(22, 22, 30, 0.35) !important;
  font-size: 0.75rem;
}

.ant-select-selection-item {
  font-size: 0.75rem;
}

.custom-select-antd .ant-select-selection-item {
  font-size: unset !important; 
}

.custom-select-antd .ant-select-selector {
  font-size: unset !important;
}

.heading-text {
  color: #16161e;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  font-family: Poppins;
}
.sub-heading-text {
  color: rgba(22, 22, 30, 0.64);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  font-family: Poppins;
}

.custom-new-table {
  // padding: unset !important;
  th {
    color: #16161e;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border-top: 1px solid #e2e2ea;
  }
  td {
    color: rgba(22, 22, 30, 0.7);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-align: start;
    border-bottom: 1px solid #e2e2ea;
  }

  .sub-text {
    color: rgba(22, 22, 30, 0.68);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
  }
}

.heading-text-small {
  font-size: 0.8125rem;
  font-weight: 500;
}

.blue-text-small {
  color: var(--Blue-50, #1f93ef);
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
}

.red-circle-container {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #ffeeef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highcharts-xaxis-labels > span {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  max-width: 75px; /* Set desired width */
}

.blue-square-background {
  display: flex;
  padding: 5px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #ebf6ff;
  cursor: pointer;
}
