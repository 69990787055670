.inactive-background {
  background: #e2e2ea;
}
.active-background {
  background: white;
}
.main-card-container-action {
  flex: 1;
  border-radius: 8px;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  height: 400px;
  max-width: 400px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
  .header-image-container {
    max-height: 72px;
    cursor: pointer;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .report-card-header {
    padding: 24px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: white;
    cursor: pointer;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    height: 50%;
    
    .info-container-action {
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      .label {
        color: #16161e;
        font-size: 14px;
        font-weight: 600;
      }
      .sub-label {
        overflow: hidden;
        color: #535362;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        margin-top: 8px;
        text-wrap: nowrap;
      }
      .tag-label{
        display: flex;
        gap: 8px;
        font-size: 12px;
        font-weight: 400;
        flex-wrap: wrap;
      }
    }

    .button-container{
      display: flex;
      gap:8px;
      button{
        width: 50%;
        font-size: 14px;
      }

      @media screen and (max-width: 768px) {
        display: block;
        button{
          margin-top: 8px;
          width: 100%;
        }
      }
    }

    .input-container {
      padding: 0px 24px 24px 24px;
      .btn-disabled {
        border: 1px solid #535362;
      }
      button {
        font-size: 14px;
        margin-top: 16px;
        align-items: center;
      }
    }
  }
}

