.invoice-container {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  .sticky-table-cell {
    position: sticky;
    background-color: #fff;
    text-wrap: nowrap;
  }
}
