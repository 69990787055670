.table-scrollable{
    overflow-x: scroll;
    width: unset;
}
.esg-table-content{
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.16);
    padding: 24px 16px 24px 16px;
}

.esg-table-content .accordion-custom .show{
    padding:  unset;
}
.table-with-bullet-point{
    margin-top: -30px;
}

.govt-struct-table .form-table .no-style-input{
    width: unset;
}