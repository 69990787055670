.contributors-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px; 
    overflow-y: scroll;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      scrollbar-gutter: stable;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      scrollbar-gutter: stable;
    }
    @media (max-width: 1263px) {
      grid-template-columns: repeat(1, 1fr);
    }
}

.select-all-checkbox{
  border-radius: 6px;
  border: 1px solid var(--Neutral-10, #F2F3F7);
  background: var(--Neutral-10, #F2F3F7);
  display: flex;
  padding: 6px 9px 6px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}