.MuiBox-root::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  scrollbar-gutter: stable;
}

.MuiBox-root:hover::-webkit-scrollbar {
  width: 8px;
  scrollbar-gutter: stable;
}
.MuiBox-root:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  scrollbar-gutter: stable;
}
