body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex{
  display: flex;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.left{
  display: flex;
  justify-content: left;
  align-items: center;
}

.right{
  display:flex;
  justify-items: right;
  align-items:center;
}

.gap4column{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* .form-select option {
  background-color: rgb(209, 251, 209);
  border: 1px solid red;
} */

.red-placeholder::placeholder {
  color: red; /* Set the color to red */
}

/* 
.scrollbar-custom::-webkit-scrollbar {
  width: 10px;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px; 
  margin: 0 auto; 
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbar-custom {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.scrollbar-custom::-moz-scrollbar {
  width: 10px;
}

.scrollbar-custom::-moz-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar-custom::-moz-scrollbar-thumb {
  background: #888;
  border-radius: 15px; 
  margin: 0 auto; 
}

.scrollbar-custom::-moz-scrollbar-thumb:hover {
  background: #555;
} */



input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}


.custome-table-borderTop{
  border-top: 1px solid #c6cbd9;
  background-color: red;
}