.main-page-title {
  font-size: 20px;
  font-weight: 500 !important;
}

.sub-details {
  color: rgba(22, 22, 30, 0.7);
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}

.white-pill {
  background: white;
  border-radius: 12px;
  border: 1px solid #e2e2ea;
  padding: 6px 12px;
  font-size: 12px;
  position: relative;
  overflow: hidden;
  z-index: 10;
  white-space: nowrap;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--fill-width, 0%);
    background-color: var(--fill-color);
    z-index: -1;
  }
}
.select-text {
  color: #16161e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.br-8 {
  .ant-select-selector {
    border-radius: 8px !important;
  }
}

.question {
  color: #16161e;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
}
.brsr-input {
  .ant-input {
    border-radius: unset;
    border-left: unset;
    border-right: unset;
    padding: 8px;
  }
  .brsr-select {
    .ant-select-selector {
      border-radius: unset;
      border-left: unset;
      border-right: unset;
      outline: none;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: unset;
    border-left: unset;
    border-right: unset;
  }
}

.brsr-table {
  table {
    width: 100%;
    border-collapse: collapse;

    th {
      background: rgba(80, 207, 171, 0.02);
      border-top: 1px solid var(--Progress-GD, #50cfab);
      border-bottom: 1px solid var(--Progress-GD, #50cfab);
      border-left: 1px solid var(--Progress-GD, #50cfab);
      padding: 12px;
      color: rgba(22, 22, 30, 0.7);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }

    td {
      padding: 8px;
      color: rgba(22, 22, 30, 0.35);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      align-items: flex-start;
      border: 1px solid var(--Neutral-50, #c6cbd9);
    }
    td:first-child,
    th:first-child {
      border-left: unset;
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }

    th.column-grey,
    td.column-grey {
      background-color: #f6f9fa;
    }

    th.column-dark-grey,
    td.column-dark-grey {
      background-color: #f9f9f9;
    }
  }

  .brsr-number-input {
    border: unset;
    outline: unset;
    width: 100%;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    height: 6px;
    scrollbar-gutter: stable;
  }

  &::-webkit-scrollbar {
    height: 6px;
    scrollbar-gutter: stable;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c6cbd9;
    border-radius: 8px;
    scrollbar-gutter: stable;
  }

  &.border-left {
    border-left: 1px solid var(--Progress-GD, #50cfab);
  }
}
.brsr-table-sectionB {
  table {
    width: 100%;
    border-collapse: collapse;

    th {
      background: rgba(80, 207, 171, 0.02);
      border-top: 1px solid var(--Progress-GD, #dc96c2);
      border-bottom: 1px solid var(--Progress-GD, #dc96c2);
      border-left: 1px solid var(--Progress-GD, #dc96c2);
      padding: 12px;
      color: rgba(22, 22, 30, 0.7);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }

    td {
      padding: 8px;
      color: rgba(22, 22, 30, 0.35);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      align-items: flex-start;
      border: 1px solid var(--Neutral-50, #c6cbd9);
    }
    td:first-child,
    th:first-child {
      border-left: unset;
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }
  }

  .brsr-number-input {
    border: unset;
    outline: unset;
    width: 100%;
  }

  &::-webkit-scrollbar {
    height: 8px;
    scrollbar-gutter: stable;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff76;
    scrollbar-gutter: stable;
  }
}

.brsr-addRow-button {
  .ant-btn.ant-btn-lg {
    border-radius: unset;
    border: unset;
    background: var(--Other-Body-Background, #f9f9f9);
    color: var(--Blue-50, #1f93ef);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }

  .button-scroll-size {
    border-radius: unset;
    border: unset;
    background: var(--Other-Body-Background, #ebf6ff);
    color: var(--Blue-50, #1f93ef);
    width: 24px;
    height: 24px;
  }
}

.brsr-scroll-button {
  .ant-btn.ant-btn-lg {
    border-radius: unset;
    border: unset;
    // color: var(--Blue-50, #1f93ef);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
}

.brsr-no-style-input {
  width: 100%;
  border: unset;
  outline: unset;
  text-align: end;
}

.brsr-radio-input {
  border: 1px solid #9a9aaf;
  padding: 8px;
  border-left: unset;
  border-right: unset;

  .css-dmmspl-MuiFormGroup-root {
    flex-direction: row;
  }
}

.table-radio-button {
  .css-dmmspl-MuiFormGroup-root {
    flex-direction: row;
  }
}
.close-icon-placement {
  .x-icon {
    margin-top: -10px;
  }
}

.blue-select {
  background: #f6f9fa !important;
}
.info-container {
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #caeff9;
  background: rgba(97, 183, 205, 0.02);
  color: rgba(22, 22, 30, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 32px;
  width: fit-content;
}

.horizontal-table-input {
  .css-dmmspl-MuiFormGroup-root {
    display: flex !important;
    flex-direction: row !important;
  }
}

.align-right {
  text-align: right;
}

.blue-link-text {
  color: var(--Blue-50, #1f93ef);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ffa11e;
  font-size: 12px;
  font-weight: 500;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover-red-border {
  border: red !important;
}

.bold-text {
  font-weight: 500 !important;
}

.column-grey {
  background-color: #f6f9fa;
}

.column-dark-grey {
  background-color: #f9f9f9;
}
