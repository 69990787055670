.measure-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .cardContentContainer {
    height: 65vh;
    overflow: auto;
  }
  .measureCardContainer {
    width: 100%;
    .scopeDesc {
      margin: 0px 0px 20px 0px;
      .scopeDescriptionPara {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: bold;
      }
      .descriptionOfScope {
        font-weight: 14px;
      }
    }
    .cardsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 32px;
    }
  }
}
