.report-list-mainwrapper {
  .toggle-buttons {
    display: inline-flex;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #eaebf1;
    background: #fff;
    padding: 4px 0px 4px 4px;
  }

  .report-table-wrapper {
    border-radius: 8px;
    border: 1px solid #e2e2ea;
    background: #fff;

    .sub-heading-text {
      color: rgba(22, 22, 30, 0.68);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .select-box {
    padding: 24px 24px 16px 24px;
  }

  .report-table {
    // padding: unset !important;
    th {
      color: rgba(22, 22, 30, 0.68);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
    }
    td {
      color: rgba(22, 22, 30, 0.9);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      text-align: start;
      border-bottom: 1px dashed #e2e2ea;
    }
    tr:nth-of-type(even) {
      background-color: #f9f9f9;
    }
    table tr td:last-child {
      border: none;
    }
    .sub-text {
      color: rgba(22, 22, 30, 0.68);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;
    }
  }
  .MuiTableCell-root {
    white-space: nowrap;
  }
}

.download-again-button {
  color: #1f93ef;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
}

.custom-select .ant-select-selector .ant-select-selection-placeholder {
  color: #16161eb2;
}

.dropdown-reporting-year {
  padding: 16px;
  width: max-content;

  .year-select-container {
    display: flex;
    // color: #7e7e8f;
    // border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;
    width: auto;

    .year-select {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      font-family: "Poppins", sans-serif;
      flex: 1 1 auto;

      p {
        text-align: center;
        text-wrap: nowrap;
        color: #16161e;
        text-align: right;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .active-year {
      width: fit-content;
      border-bottom: 2px solid #1f93ef;
      color: white;
    }
  }
}
