.input-table {
  background-color: white;
  border-top-left-radius: 0 !important;
  position: absolute;
  .disabled {
    background-color: #e9e9ed !important;
    width: 100%;
  }
  .calculateCell {
    min-width: 150px;
    right: 76px;
  }
  .editBtn {
    padding: 5px !important;
  }
  .stickyTableCellLeft {
    position: sticky;
    background: white;
    left: 0;
  }

  .btnDisabled {
    background: #c6cbd9 !important;
    border: #c6cbd9 !important;
  }

  input,
  select {
    width: 150px;
  }

  .addRowbtn {
    margin: 20px !important;
  }
  .addRowStyle {
    width: 140px !important;
  }

  .modalInputTable {
    width: 100%;
  }

  .cancelButtonRed {
    background: #ffebee;
    padding: 8px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .select-wrap {
    border: 1px solid #777;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0 5px 5px;
    width: 200px;
    background-color: #ebebeb;
  }

  .select-wrap label {
    font-size: 10px;
    text-transform: uppercase;
    color: #777;
    padding: 2px 8px 0;
  }

  .label-select {
    background-color: #ebebeb !important;
    border: 0px !important;
  }

  .ant-picker-input > input {
    color: black !important;
  }

  .white-nowrap {
    white-space: nowrap;
  }

  .cancelButtonRed:hover {
    background: #fedfe3;
  }

  .cellDisabled {
    cursor: not-allowed !important;
  }
}

.hover-icon {
  border-radius: 100%;
  padding: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e5e7eb;
  }
}

.hybrid-input {
  height: 40px;
  width: 240px;
  .ant-input-wrapper {
    height: 100%;
  } 
  .ant-input {
    height: 40px !important;
  }
}

