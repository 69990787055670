.esg-breakdown-wrapper {
  background: white;
  margin-top: 16px;
  border-radius: 8px;

  .esg-beakdown-table {
    border-collapse: separate;
    border-radius: 6px;
    table-layout: fixed;
    width: 100%;
    padding: 0 24px 24px 24px;
    overflow: auto;

     table{
        width: 100%;
     }

     td,
     th {
       border: solid #c6cbd9 1px;
       font-size: 14px;
       font-weight: 500;
     }
   
     th {
       background-color: #f2f3f7;
       padding: 12px 32px;
       text-align: start;
       text-wrap: nowrap;
       color: #2e2e3a;
       font-family: Poppins;
       font-size: 14px;
       font-style: normal;
       font-weight: 500;
     }
     td {
       padding: 14px 32px;
       color: #2e2e3a;
       font-family: Poppins;
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
     }
     
  }
}

.large-width-columns {
    max-width: 300px;
  }