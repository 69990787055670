h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #64b5f6 !important;
    background-color: white !important;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    background-color: #64b5f6 !important;
  }

  .checkbox-selection {
    display: flex;
    flex-direction: column;
    width: 390px;
    padding: 6px 12px 6px 8px;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #c6cbd9;

    .blue-background {
      border-radius: 8px;
      margin-top: 16px;
      background: #f0f9ff;
      padding: 8px 12px 8px 8px;
    }

    .flex-cont {
      display: flex;
      justify-content: space-between;
    }

    .subtitle {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    .form-file-upload-new {
      max-width: 100%;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 16px;
      cursor: pointer;

      #input-file-upload {
        display: none;
      }

      #drag-file-element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }

      #label-file-upload {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 1rem;
        border-style: dashed;
        border-color: #cbd5e1;
        padding: 9px;
      }

      #label-file-upload.drag-active {
        background-color: #ffffff;
      }

      .button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        margin-top: 15px;
      }

      .upload-icon {
        display: flex;
        width: 44px;
        height: 44px;
        padding: 9px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background: #f2f3f7;
      }

      .upload-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }

    // .upload-button {
    //   cursor: pointer;
    //   padding: 0.25rem;
    //   font-size: 1rem;
    //   border: none;
    //   background-color: transparent;
    // }

    // .upload-button:hover {
    //   text-decoration-line: underline;
    // }
  }

  .modalInput,
  .dropdown-antd {
    width: 340px !important;
    margin-top: 16px;
  }
  .ant-select {
    display: block !important;
  }

  .min-char-lable {
    display: flex;
    flex-direction: row;
    font-size: small;
    margin-left: 190px;
    padding-top: 6px;
    color: #16161eb2;
  }
}

.next-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }
}

.wide-width {
  width: 340px !important;
}

.file-container {
  display: flex;
  padding: 4px 6px 4px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid var(--Small-Button-UE-Stroke, #e1fde1);
  background: var(--Small-Button-UE-Fill, #f0fff0);

  .file-name {
    color: #16161e;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }

  .icon {
    width: 12px;
    height: 12px;
    fill: #16161e;
    cursor: pointer;
  }
}

.custom-checkbox .ant-checkbox-inner {
  border: 1.6px solid #16161e59 !important;
}

.no-year-left-container{
display: flex;
flex-direction: row;
gap: 8px;
margin-top: 24px;
margin-bottom: 24px;
max-width: 346px;
}

.no-year-left-icon{
  display: flex;
  align-items: start;
}

.no-year-left-content{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.no-year-left-text{
  color: #16161E;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.no-year-left-subtext{
  color: rgba(22, 22, 30, 0.70);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.no-year-left-button{
  background: white !important;
  color: #16161ee0 !important;
  border-color: #c6cbd9 !important;
  width: 110px;
  height: 32px;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid var(--Neutral-50, #C6CBD9);
  padding: 4px 6px 4px 16px;

  &:hover {
    background: #c6cbd9 !important;
  }
}