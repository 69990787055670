$neutral-50: #c6cbd9;
$text-on-white-high-emphasis: #16161e;

.remark-history-container {
  display: flex;
  padding: 5px 8px 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 34px;
  // border-radius: 6px;
  background: var(--Other-Body-Background, #f9f9f9);
  width: fit-content;

  .content {
    color: var(--Blue-50, #1f93ef);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
}

.remark-history-drawer {
  .custom-drawer {
    margin-top: unset;
  }
  .close-btn {
    display: none;
  }
  .reply-text-button {
    color: rgba(22, 22, 30, 0.35);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .remark-msg-container {
    max-height: 47vh;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      scrollbar-gutter: stable;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      scrollbar-gutter: stable;
    }
  }
}
