.team-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e2e2ea;
  background: #fff;
  padding: 14px;
  //   max-width: 440px;

  .user-details {
    .user-name {
      color: rgba(22, 22, 30, 0.88);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .user-email {
      color: rgba(22, 22, 30, 0.64);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      max-width: 150px;
    }
  }
}

.selected-member {
  border: 1px solid #bfe3ff;
  background: #f0f9ff;
}

.selected-member-green {
  border: 1px solid #50CFAB;
  background: #F0FFF0;
}

.assign-role{
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: unset;
    background: unset;
    outline: unset;
    box-shadow: unset !important;
    gap: 8px;
  }
  &::placeholder{
    color: red;
  }
 }

.select-text {
  color: #16161e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
