.empty-assigned-state-custom {
    border-radius: 8px;
    border: 0.6px solid #f2f3f7;
    // background: #f2f3f7;
    background: #F9F9F9;
    display: flex;
    padding: 24px 19px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
  }