.emt-tag {
  border-radius: 45px;
  background: var(--Blue-10, #b0d2a6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 2px 12px 2px 12px;
  margin-top: 30px;
}

.scl-tag {
  border-radius: 45px;
  background: var(--Blue-10, #a6cfec);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 2px 12px 2px 12px;
}

.gvrn-tag {
  border-radius: 45px;
  background: var(--Blue-10, #e6b0b0);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 2px 12px 2px 12px;
}

.main-header-info {
  font-size: 24px;
  margin-top: 12px;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.download-icon-container {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 20px 20px;
  width: 32px;
  transition: 200ms;
  &:not(.disabled-download-btn) {
    border: 1px solid #1f93ef;
    img {
      filter: invert(36%) sepia(44%) saturate(4516%) hue-rotate(83deg) brightness(98%) contrast(101%);
    }
  }
  &:not(.disabled-download-btn):hover {
    background-color: #1f93ef;
    transition: 200ms;
    cursor: pointer;
    img {
      filter: invert(1);
    }
  }
}

.disabled-download-btn {
  background: #e2e2ea;
}

.progress-bars {
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 11;
  background: white;
  padding: 24px 0px;

  .page-title-container {
    overflow: hidden;
    margin: auto;
    padding: 0px 5px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      text-align: center;
    }
  }
}

.progress-bar-container {
  background: #e2e2ea;
  height: 2px;
  flex: 1;
  margin-top: 8px;
}

.progress-title-bar-container {
  margin: 8px 8px;
  width: 15%;
  cursor: pointer;

  &:first-child {
    margin-left: 16px;
  }

  &:last-child {
    margin-right: 16px;
  }
}

.progress-bar {
  background: #262631;
  height: 4px;
}

.progress-bar-complete {
  background: #262631;
  height: 2px;
}

.container-active {
  height: 4px;
}

.text-inactive {
  color: #9a9aaf;
}

.page-content {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  margin-top: 24px;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.16);
  padding: 0px 28px 24px 28px;
  overflow-y: auto;
  flex: 1 1 auto;

  .button-style {
    max-width: 275px;
    font-size: 14px;
  }

  .content-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
  }
}

.form-main-heading {
  font-size: 20px;
  font-weight: 600;
}

.form-main-container {
  margin-top: 24px;
}

.form-bullet-points-header {
  font-size: 24px;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 24px !important;

  .form-bullet-subpoints {
    color: #7e7e8f;
    font-size: 14px;
    font-weight: 400;
  }
}

.form-bullet-points-div {
  margin-bottom: 16px !important;

  .form-bullet-subpoints {
    color: #7e7e8f;
    font-size: 14px;
    font-weight: 400;
  }
}

.form-bullet-points {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px !important;
  margin-top: 32px !important;

  .form-bullet-subpoints {
    color: #7e7e8f;
    font-size: 14px;
    font-weight: 400;
  }
}

.form-subbullet-points {
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px !important;
  margin-bottom: 16px !important;

  .form-bullet-subpoints {
    color: #7e7e8f;
    font-size: 14px;
    font-weight: 400;
  }
}

.form-textfield-container {
  .custom-textfield {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;

    label {
      font-weight: bold;
      margin-bottom: 8px;
    }

    textarea {
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 8px;
      font-size: 16px;
      resize: vertical;

      &:focus {
        border: 1px solid #1f93ef !important;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}

.form-table {
  border-collapse: separate;
  border: solid #7e7e8f 1px;
  border-radius: 6px;
  table-layout: fixed;
  width: 100%;

  .no-style-input {
    border: none;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }

  td,
  th {
    border-left: solid #7e7e8f 1px;
    border-top: solid #7e7e8f 1px;
    padding: 14px 27px;
    font-size: 14px;
    font-weight: 500;
  }

  th {
    background-color: #f2f3f7;
    border-top: none;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }
  .small-width-columns {
    width: 80px;
    padding: 0px !important;
    text-align: center;
  }

  .xs-column {
    width: 40px;
    padding: 0px !important;
    text-align: center;
  }

  .medium-width-columns {
    width: 200px;
  }

  .large-width-columns {
    max-width: 300px;
  }
}

.form-table-header {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px !important;
  margin-top: 24px !important;
}

.border-top {
  border-top: 1px solid #7e7e8f !important;
}

.border-top-left {
  border-top: 1px solid #7e7e8f !important;
  border-left: 1px solid #7e7e8f !important;
}

.border-left {
  border-left: 1px solid #7e7e8f !important;
}

.td-no-padding {
  padding: 0px 0px 0px 5px !important;
}

.small-font {
  font-size: 12px;
}

// textarea {
//   // min-height: 60px;
//   overflow-y: auto;
//   word-wrap:break-word
//   }
