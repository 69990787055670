.two-button-container {
  display: flex;
  gap: 8px;
  width: 550px;
}

.bg-sb{
  background-color: #E3F2FD;
}

.main-modal-container {
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 20px;
  margin-right: -20px;

  .button-container {
    padding-top: 24px;
    position: sticky;
    bottom: 0;
    background: white;
    display: flex;
    gap: 8px;
    margin-top: 8px;
    z-index: 10;
  }

  &::-webkit-scrollbar {
    width: 8px;
    scrollbar-gutter: stable;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    scrollbar-gutter: stable;
  }
}

.divider-container {
  margin-top: 20px;
  width: 100%;

  .line-divider {
    color: #1f93ef;
    text-align: left;
    position: relative;
    margin-bottom: 16px;
    font-size: 12px !important;
  }

  .line-divider .span-line-divider {
    background: #fff;
    padding: 0px 20px 0px 0px;
    position: relative;
    z-index: 5;
  }

  .line-divider:before {
    content: "";
    display: block;
    border-top: solid 2px #1f93ef;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 7px;
    z-index: 1;
  }
}

.main-image-container {
  display: flex;
  gap: 10px;

  .image-container-div-fac {
    position: relative;
    width: 150px;
    height: 150px;

    .upload-image-div {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 100%;
    }
  }

  .add-image-icon {
    position: absolute;
    right: 17px;
    bottom: 0px;
    width: 30px;
    height: 30px;
    background-color: #1f93ef;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: bottom 0.3s ease;
    border: 1px solid white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

    &:hover {
      bottom: 5px;
    }
  }
}

.border-impact {
  border-right: 1px solid #9A9AAF;
  padding-right: 73px;

  .selection-tabs-modal {
    display: flex;
    color: #7e7e8f;
    border-radius: 4px;
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;

    .tab-select {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      font-family: "Poppins", sans-serif;
      flex: 1 1 auto;
      border-top: 1px solid #7e7e8f;
      border-bottom: 1px solid #7e7e8f;
      border-right: 1px solid #7e7e8f;

      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }

      &:first-child {
        border-radius: 4px 0px 0px 4px;
        border-left: 1px solid #7e7e8f;
      }

      p {
        text-align: center;
      }
    }

    .active-tab-red {
      width: fit-content;
      background: #ffebee;
      border: 1px solid #d32f2f;
      color: #d32f2f;
    }
    .active-tab-green {
      width: fit-content;
      background: #d6f4ee;
      border: 1px solid #007a64;
      color: 007A64;
    }
    .active-tab-yellow {
      width: fit-content;
      background: #fff9c4;
      border: 1px solid #f57f17;
      color: F57F17;
    }
  }
}
