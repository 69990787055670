.filterContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid gray;
  padding-bottom: 15px;

  @media screen and (max-width: 768px) {
    display: block;
  }

  .disabledSelector > div {
    background-color: #e9e9ed !important;
  }

  .modalInputSelector > div {
    background: #f2f3f7 !important;
  }

  .calenderSelect {
    margin-left: 20px;
    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }

  .modalInputSelect {
    background-color: #f2f3f7 !important;
    color: black;
    font-size: 16px !important;
  }

  .editBtnContainer {
    margin-left: 25px;
  }

  .buttonContainer {
    display: flex;
    justify-content: end;
  }
}

.tab-wrapper {
  display: flex;
  margin-top: 5px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    background-color: transparent;
    height: 8px;
    scrollbar-gutter: stable;
  }
  &:hover::-webkit-scrollbar {
    height: 8px;
    scrollbar-gutter: stable;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    scrollbar-gutter: stable;
  }
  .addCategoryContainer {
    display: flex;
    height: 48px;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    justify-content: space-around;
    padding: 0px 8px;
    gap: 14px;
    cursor: pointer;
    text-wrap: nowrap;
  }
}

.table-wrapper {
  height: 100%;
  overflow: auto;
  position: relative;
  &::-webkit-scrollbar {
    background-color: transparent;
    height: 8px;
    scrollbar-gutter: stable;
    width: 10px;
  }
  &:hover::-webkit-scrollbar {
    height: 8px;
    scrollbar-gutter: stable;
    width: 10px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: #00000033;
    scrollbar-gutter: stable;
  }
}

.ant-picker-cell-inner {
  &:hover {
    background: #1F93EF !important;
    color: white;
  }
}

.ant-picker-cell-disabled {
  cursor: not-allowed;
  .ant-picker-cell-inner {
    pointer-events: all;
    cursor: not-allowed;
    background: transparent !important;
    color: rgba(0, 0, 0, 0.25);
  }
}

.selectCardsContainer {
  width: 80vw;
  height: 80vh;
}
