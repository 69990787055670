// Button.scss
.button-wrapper {
    // display: inline-flex;
    // align-items: flex-start;
    // border-radius: 8px;
    // border: 1px solid #eaebf1;
    // background: #fff;
    // padding: 4px 0px 4px 4px;
  
    .button {
      padding: 6px 12px;
      border: none;
      border-radius: 8px;
      background: #fff;
      cursor: pointer;
      margin-right: 4px;
      transition: background 0.3s, color 0.3s;
      color: rgba(22, 22, 30, 0.32);
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
  
      &:last-child {
        margin-right: 0;
      }
  
      &.active {
        border-radius: 6px;
        background: #64b5f6;
        color: #fff;
      }
    }
  }
  