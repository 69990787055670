.env-filter-wrapper{
  .custom-drawer{
    margin-top: 110px;
  }
}

.envSearchFilter {
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 8px;
  align-items: center;
  margin-top: -40px;
}

.filter-text {
  color: #16161e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.filter-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  height: 100%;

  .allfilter-container {
    overflow-y: auto;
    max-height: 65vh;
    padding-right: 15px;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      scrollbar-gutter: stable;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      scrollbar-gutter: stable;
    }
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-right: 15px;
    padding-bottom: 160px;

    .btn,
    .ant-btn {
      flex: 1;
      max-width: 120px;
    }
  }

  .dropdown-lebel {
    color: #16161e;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4px !important;
  }
}

.mainEnv-wrapper {
  background: white;
  border-radius: 8px;
}
.envContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 24px;
  overflow: auto;

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;

    th,
    td {
      border: 1px solid #c6cbd9;

      &:first-child {
        border-radius: 4px;
      }
    }

    th {
      background-color: #f2f3f7;
      color: #2e2e3a;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    td {
      color: #2e2e3a;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      text-wrap: nowrap;
    }
  }

  .scrollable {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 6px;
      width: 8px;
      scrollbar-gutter: stable;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      scrollbar-gutter: stable;
      border-radius: 8px;
    }
    .table td {
      padding: 28px 8px 28px 8px;
    }
  }

  .first-table .table td {
    padding: 30px 8px 30px 8px;
  }
}

.envContainer .thirdtable .table td {
  padding: 15px 0 16px 0;
}

.dropdownFyContinar {
  padding: 16px;


  .year-select-container {
    display: flex;
    margin-bottom: 16px;
    cursor: pointer;
    width: auto;

    .year-select {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      font-family: "Poppins", sans-serif;
      flex: 1 1 auto;

      p {
        text-align: center;
        text-wrap: nowrap;
        color: #16161e;
        text-align: right;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .active-year {
      width: fit-content;
      border-bottom: 2px solid #1f93ef;
      color: white;
    }
  }
  .custom-select .ant-select-selector {
    border: 1px solid #9a9aaf !important;
  }

  .custom-select .ant-select-selection-placeholder {
    color: black;
  }
}

.selected-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 3px;
  margin-bottom: 8px;
  min-height: 35px;
  // position: absolute;

  .filter-chip {
    display: flex;
    align-items: center;
    border-radius: 30px;
    background: #fff;
    padding: 4px 8px;
    gap: 8px;
  }
  .filter-chip span {
    // margin-right: 10px;
  }

  .filter-chip .anticon {
    cursor: pointer;
    height: 14px;
    width: 14px;
  }
}

.empty-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 70vh;
  border-radius: 8px;
}
