.add-contributors-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .all-facility-container {
    display: flex;
    width: 330px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid #e2e2ea;
    background: #f6f9fa;
  }
  .facility-list-wrapper {
    padding: 24px 16px;

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      scrollbar-gutter: stable;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      scrollbar-gutter: stable;
    }
  }
  // AddContributors.scss

  .button-container {
    display: inline-flex;
    padding: 4px 0px 4px 4px;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #eaebf1;
    background: #fff;
    align-self: flex-start;

    .button {
      padding: 6px 12px;
      border: none;
      border-radius: 8px;
      background: #fff;
      cursor: pointer;
      margin-right: 4px;
      transition: background 0.3s, color 0.3s;
      color: rgba(22, 22, 30, 0.32);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border-radius: 6px;
        background: #64b5f6;
        color: #fff;
      }
    }
  }

  .content-wrapper {
    border-radius: 8px;
    border: 1px solid #e2e2ea;
    background: #fff;
    margin-top: 24px;
    flex-grow: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      scrollbar-gutter: stable;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      scrollbar-gutter: stable;
    }
  }
  .heading-text {
    color: #16161e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .facility-name {
    color: rgba(22, 22, 30, 0.64);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .active-name {
    color: rgba(22, 22, 30, 0.84);
    font-weight: 500;
  }
  .active-sub-heading {
    color: rgba(22, 22, 30, 0.64);
    font-weight: 500;
  }
  .facility-tab {
    padding: 15px 16px;
    width: 100%;
  }
  .active-facility {
    border-radius: 6px;
    border: 1px solid #bfe3ff;
    background: #ebf6ff;
  }

  .confirm-team-wrapper {
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      scrollbar-gutter: stable;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      scrollbar-gutter: stable;
    }
    .invite-container {
      display: flex;
      padding: 0px 6px 0px 14px;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid #e2e2ea;
      background: #fff;
      // width: 60%;

      .email-input {
        flex: 1;
        padding: 8px;
        border: none;
        border-radius: 8px;
        background: #fff;
        color: rgba(22, 22, 30, 0.84);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        &:focus {
          outline: none;
        }
        &::placeholder {
          color: rgba(22, 22, 30, 0.32);
        }
      }

      .invite-button {
        display: flex;
        padding: 8.5px 23px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #ebf6ff;
        border: none;
        color: rgba(22, 22, 30, 0.32);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
        transition: background 0.3s;
        height: 28px;

        &:hover {
          background: #cce3ff;
        }
        &.invite-button-active {
          background: #64b5f6;
          color: #fff;
        }
      }
    }
  }
}

.team-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    scrollbar-gutter: stable;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    scrollbar-gutter: stable;
  }
  @media (max-width: 1290px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.toggle-buttons {
  display: inline-flex;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #eaebf1;
  background: #fff;
  padding: 4px 0px 4px 4px;
}
