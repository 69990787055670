.custom-dropdown-container {
  position: relative;
  // width: 202px;
  max-width: 100%;
}

.dropdown-trigger {
  padding: 8px 6px 8px 10px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.dropdown-arrow {
  font-size: 12px;
  color: #64748b;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  display: flex;
  flex-direction: row-reverse;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 1000;
}

.sections-list {
  width: 220px;
  max-height: 400px;
  overflow-y: auto;
  border-left: 1px solid #e2e8f0;
  padding: 0px 8px;
}

.subsections-list {
  width: 220px;
  max-height: 400px;
  overflow-y: auto;
  padding: 0px 8px;
}

.section-item {
  display: flex;
  flex-direction: row;
  gap: 8px;

  padding: 8px 0px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.subsection-item {
  display: flex;
  flex-direction: row;
  gap: 8px;

  padding: 8px 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.section-item-icon {
  display: flex;
  align-items: center;
}

.subsection-item-icon {
  display: flex;
  align-items: center;
  justify-content: start;
}

.section-item-text,
.subsection-item-text {
  display: flex;
  flex-direction: column;
}

.section-name,
.subsection-name {
  color: #16161eb2;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.section-tag,
.subsection-tag {
  color: #16161e;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.section-item:hover,
.subsection-item:hover {
  background-color: #f1f5f9;
}

.section-item.active.section-a,
.subsection-item.active.section-a {
  background-color: #ddf4e5;
}

.section-item.active.section-b,
.subsection-item.active.section-b {
  background-color: #fee5fa;
}

.section-item.active.section-c,
.subsection-item.active.section-c {
  background-color: #e0f3f8;
}
