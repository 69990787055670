.global-team-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e2e2ea;
  background: #fff;
  padding: 14px;
  flex: 1;
  width: 440px;

  .user-details-select {
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .user-name {
      color: rgba(22, 22, 30, 0.88);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .user-email {
      color: rgba(22, 22, 30, 0.64);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      white-space: normal;
      max-width: 249px;
    }
  }
}

.global-team-wrapper.first-item {
  border-radius: 8px 8px 0 0;
}

.global-team-wrapper.last-item {
  border-radius: 0 0 8px 8px;
}

.global-select-text {
  color: #16161e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
