.auitlog-table-container{
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    padding: 32px;
    margin-top: 14px;
    min-height: 551px;
}
.main-container{
    width: 700px;
    overflow: auto;
    
}
