.custom-button {
  display: inline-block;
  padding: 5px 12px;
  font-size: 12px;
  border: 1px solid #64b5f6;
  background: #64b5f6;
  color: white;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #46abff;
  }

  &:active {
    background-color: #64b5f6;
  }
}

.disable-custom-button {
  display: inline-block;
  padding: 5px 12px;
  font-size: 12px;
  background: #ebf6ff;
  border: 1px solid #ebf6ff;
  color: rgba(22, 22, 30, 0.35);
  cursor: not-allowed;
  border-radius: 4px;

  &:hover,
  &:active {
    background-color: #ebf6ff;
    color: rgba(22, 22, 30, 0.35);
  }
}
