$neutral-50: #c6cbd9;
$text-on-white-high-emphasis: #16161e;

.drawer-container {
  display: flex;
  padding: 5px 8px 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 34px;
  // border-radius: 6px;
  background: var(--Other-Body-Background, #f9f9f9);
  width: 472px;

  .content {
    color: var(--Blue-50, #1f93ef);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
}

.drawer-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .drawer-assign-button {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    cursor: pointer;
  }
}

.bulk-history-drawer {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 16px 16px 16px;

  .custom-drawer {
    margin-top: unset;
  }
  .close-btn {
    display: none;
  }
  .reply-text-button {
    color: rgba(22, 22, 30, 0.35);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .remark-msg-container {
    max-height: 47vh;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      scrollbar-gutter: stable;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      scrollbar-gutter: stable;
    }
  }

  .remark-msg-container::-webkit-scrollbar {
    display: none;
  }

  .drawer-cross-button {
    display: flex;
    padding: 5px 12px 5px 6px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--Neutral-50, #c6cbd9);

    .close-text {
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .drawer-heading {
    color: #16161e;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}

.drawer-button-container {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  background: var(--White-100, #fff);
  width: 100%;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}
