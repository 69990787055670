.accordion {
  width: 100%;
  border: 1px solid #ffffff;
  overflow: hidden;
  border: 1px solid #e2e2ea;
  border-bottom: 1px solid #e2e2ea;
  background-color: #ffffff;

  .blue-bg {
    background-color: #f0f9ff;
  }

  .single-multi-pill {
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #fcf1cc;
    background: #fff9e5;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    gap: 20px;

    .checkbox-start {
      .ant-checkbox {
        align-self: start !important;
      }
    }
  }

  &__content {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
    padding: 0 15px;
    background: #f9f9f9;
    overflow-y: scroll;
    // margin-right: 8px;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
      scrollbar-gutter: stable;
    }

    &::-webkit-scrollbar {
      width: 6px;
      scrollbar-gutter: stable;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c6cbd9;
      border-radius: 8px;
      scrollbar-gutter: stable;
    }
  }

  &__text {
    padding: 15px 0;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 15px;
  }
}

.single-fac {
  display: flex;
  padding: 5px 9px 5px 8px;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  border: 0.5px solid var(--Neutral-20, #eaebf1);
  background: var(--Neutral-10, #f2f3f7);
  width: 50%;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 500;
}

.gray-select {
  .ant-select-selector {
    background: #f6f9fa !important;
    color: #333 !important;
    border-color: 1px solid var(--Neutral-30, #e2e2ea);

    &:hover {
      border-color: 1px solid var(--Neutral-30, #b9b9bc);
    }

    .ant-select-selection-placeholder {
      color: rgba(22, 22, 30, 0.7) !important;
    }

    .ant-select-selection-item {
      color: rgba(22, 22, 30, 0.7) !important;
    }
  }

  .ant-select-arrow {
    color: rgba(22, 22, 30, 0.7) !important;
  }
}

.gray-pill {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  border: 0.5px solid var(--Neutral-20, #eaebf1);
  background: var(--Neutral-5, #f6f9fa);
  min-width: 100px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 24px;
  color: rgba(22, 22, 30, 0.7);
}
