.accordion-custom {
  width: 100%;

  .title {
    cursor: pointer;
  }

  .black-bg {
    background-color: #1c1c1c;
  }

  .content {
    max-height: 0;
    overflow: hidden;
    padding: 0px 0px 0px 25px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .show {
    height: auto;
    max-height: 9999px;
    padding: 0px 0px 0px 25px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  }

  .line-divider {
    color: #1f93ef;
    text-align: left;
    position: relative;

    .span-line-divider {
      background: #fff;
      padding: 0 20px 0px 0px;
      position: relative;
      z-index: 5;

      .horizontal {
        transform: rotateZ(180deg);
        transition: 0.5s;
      }

      .vertical {
        transform: rotateZ(0deg);
        transition: 0.5s;
      }
      
      .right-arrow {
        transform: rotateZ(90deg);
        transition: 0.5s;
      }

      .left-arrow {
        transform: rotateZ(270deg);
        transition: 0.5s;
      }
    }
  }

  .line-divider:before {
    content: "";
    display: block;
    border-top: solid 2px #1f93ef;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 10px;
    z-index: 1;
  }

  .dark-line-divider {
    color: #fff;
    text-align: left;
    position: relative;
    padding-left: 24px;

    .span-line-divider-dark {
      background: transparent;
      position: relative;
      z-index: 5;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 5px;
      }

      .horizontal {
        transform: rotateZ(180deg);
        transition: 0.5s;
      }

      .vertical {
        transform: rotateZ(0deg);
        transition: 0.5s;
      }
    }
  }
  .dark-line-divider1 {
    color: #fff;
    text-align: left;
    position: relative;
    padding-left: 16px;

    .span-line-divider-dark {
      background: transparent;
      position: relative;
      z-index: 5;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 5px;
      }

      .horizontal {
        transform: rotateZ(180deg);
        transition: 0.5s;
      }

      .vertical {
        transform: rotateZ(0deg);
        transition: 0.5s;
      }
    }
  }
}

.textWrapper {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
  text-decoration: none;
  text-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden {
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}
