.textarea-container {
  position: relative;
  // margin: 20px 0;
}

textarea {
  width: 100%;
  border: 1px solid #9A9AAF;
  // border-radius: 8px;
  border-left: unset;
  border-right: unset;
  overflow: hidden;
  resize: none;
  padding: 16px 16px 16px 16px;
  color: rgba(22, 22, 30, 0.90);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  outline: none;
}

.character-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color:  rgba(22, 22, 30, 0.38);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
